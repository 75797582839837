var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{ref:"table",attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"form-scheme":_vm.formScheme,"resource":_vm.resource,"custom-opt":_vm.customOpt,"allow-default-actions":false,"hide-top-action-btn":false,"show-select":true},on:{"currentItems":_vm.currentItems},scopedSlots:_vm._u([{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [(!item.team_id)?_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('affiliates.teams.table.btn.update'),"icon":"pencil","can":"affiliates.teams.update","disabled":!!item.team_id,"fn":function () { return _vm.$eventBus.$emit((_vm.resource + "_updateItem"), item); }}}):_vm._e(),(!item.team_id)?_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('affiliates.teams.table.btn.remove'),"icon":"delete","can":"affiliates.teams.update","fn":function () { return _vm.$eventBus.$emit((_vm.resource + "_removeItem"), item); }}}):_vm._e(),(!item.team_id)?_c('table-btn',{attrs:{"item":item,"tooltip-text":"Edit team permissions","icon":"folder-account-outline","can":"affiliates.teams.permissions","fn":function () { return _vm.teamPermissions(item); },"color":item.permissions !== undefined && item.permissions.length > 0 ? 'primary' : 'grey'}}):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.status === true ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.team_id",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.team_id !== false ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected,"readonly":item.disabled === true,"disabled":item.disabled === true},on:{"input":function($event){return select($event)}}})]}}],null,true)})],1)],1),_c('team-permissions',{ref:"teamPermissionsDialog",attrs:{"can":'affiliates.teams.permissions'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }