<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          ref="table"
          :title="title"
          :table-headers="tableHeaders"
          :form-scheme="formScheme"
          :resource="resource"
          :custom-opt="customOpt"
          :allow-default-actions="false"
          :hide-top-action-btn="false"
          :show-select="true"
          @currentItems="currentItems"
        >
          <template #action-buttons="{ item }">
            <table-btn
              v-if="!item.team_id"
              :item="item"
              :tooltip-text="$t('affiliates.teams.table.btn.update')"
              :icon="`pencil`"
              :can="`affiliates.teams.update`"
              :disabled="!!item.team_id"
              :fn="() => $eventBus.$emit(`${resource}_updateItem`, item)"
            />
            <table-btn
              v-if="!item.team_id"
              :item="item"
              :tooltip-text="$t('affiliates.teams.table.btn.remove')"
              :icon="`delete`"
              :can="`affiliates.teams.update`"
              :fn="() => $eventBus.$emit(`${resource}_removeItem`, item)"
            />
            <table-btn
              v-if="!item.team_id"
              :item="item"
              :tooltip-text="`Edit team permissions`"
              :icon="`folder-account-outline`"
              :can="`affiliates.teams.permissions`"
              :fn="() => teamPermissions(item)"
              :color="item.permissions !== undefined && item.permissions.length > 0 ? 'primary' : 'grey'"
            />
          </template>

          <template #[`item.status`]="{ item }">
            <v-icon>{{ item.status === true ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </template>

          <template #[`item.team_id`]="{ item }">
            <v-icon>{{ item.team_id !== false ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </template>

          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              :ripple="false"
              :value="isSelected"
              :readonly="item.disabled === true"
              :disabled="item.disabled === true"
              @input="select($event)"
            />
          </template>
        </table-data>
      </v-col>
    </v-row>
    <team-permissions
      ref="teamPermissionsDialog"
      :can="'affiliates.teams.permissions'"
    />
  </v-container>
</template>

<script>
import TeamPermissions from './dialogs/TeamPermissions'

export default {
  name: 'Affiliates',
  components: {
    TeamPermissions
  },
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('affiliates.teams.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'affiliates.teams',
    syncLoading: false,
    customOpt: {
      itemsPerPage: 50
    },
    selected: []
  }),
  mounted() {
    this.title = this.$t('affiliates.teams.title')
    this.tableHeaders = [
      {
        text: this.$t('affiliates.teams.table.name'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('affiliates.teams.table.affiliatesNum'),
        value: 'affiliatesNum',
        width: '100px',
        align: 'center'
      },
      // {
      //   text: this.$t('affiliates.teams.table.fromCRM'),
      //   value: 'team_id',
      //   width: '100px',
      //   align: 'center'
      // },
      {
        text: this.$t('affiliates.teams.table.status'),
        value: 'status',
        width: '100px',
        align: 'center'
      }
    ]
    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('affiliates.teams.tableForm.name'),
        createRules: 'req',
        updateRules: 'req',
        md: 12
      },
      // affiliate_hash: {
      //   type: 'text',
      //   label: this.$t('affiliates.tableForm.affiliate_hash'),
      //   forCreateOnly: true
      // },
      status: {
        type: 'switch',
        label: this.$t('affiliates.teams.tableForm.status'),
        md: 12
      }
    }
  },
  methods: {
    async teamPermissions(team) {
      await this.$refs.teamPermissionsDialog.open(team, {})
    },
    currentItems(items) {
      items.forEach((i) => {
        if (i.team_id !== false) {
          this.$set(i, 'disabled', true)
        }
      })
    }
  }
}
</script>
