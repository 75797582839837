<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :scrollable="options.scrollable"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title>Set special team rights</v-card-title>
      <v-divider />
      <v-card-text class="px-3">
        <div
          v-for="(perm, key) of permissions"
          :key="key"
          class="perm-section"
        >
          <div class="text-uppercase font-weight-bold perm-section-title">
            <v-icon>mdi-{{ perm.icon }}</v-icon>
            {{ perm.id }}
          </div>

          <v-chip-group
            v-model="selectedPermissions"
            column
            multiple
            class="perm-section-content"
          >
            <v-chip
              v-for="(action, actKey) of perm.actions"
              :key="`${perm.id}.${actKey}`"
              filter
              outlined
              :value="`${perm.id}.${action}`"
            >
              {{ $helper.capFirstLetter(action) }}
            </v-chip>
            <div
              v-if="perm.children"
              class="ml-5"
            >
              <div
                v-for="(child, childKey) of perm.children"
                :key="childKey"
              >
                <div class="mt-2 mb-2 text-uppercase">
                  <v-icon>mdi-{{ child.icon }}</v-icon>
                  {{ $t(`users.permissions.tree.${child.name}`) }}
                </div>
                <v-chip
                  v-for="(subAction, subActKey) of child.actions"
                  :key="`${perm.id}.${subActKey}`"
                  filter
                  outlined
                  :value="`${child.id}.${subAction}`"
                >
                  {{ $helper.capFirstLetter(subAction) }}
                </v-chip>
              </div>
            </div>
          </v-chip-group>
        </div>
        <!-- code>{{ getSelectedPermissions }}</code-->
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="red darken-1"
          text
          @click="cancel"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AffiliatesTeamPermissions',
  components: {},

  data () {
    return {
      dialog: false,
      options: {
        color: '',
        width: 500,
        zIndex: 200,
        noConfirm: false,
        scrollable: true
      },
      permissions: [],
      selectedPermissions: [],
      affiliatesTeam: {}
    }
  },
  computed: {
    getSelectedPermissions() {
      return this.selectedPermissions
    }
  },
  async mounted () {
    if (this.$currentUser.global === true && this.$can('affiliates.teams.permissions') === true) {
      await this.$store.dispatch('affiliatesTeams/getPermissionsList')
    }
    const permissionsObjects = this.$store.getters['affiliatesTeams/permissionsList']
    this.permissions = permissionsObjects.permissions
  },
  methods: {
    open (team, options) {
      this.affiliatesTeam = team
      this.dialog = true
      this.options = Object.assign(this.options, options)

      this.selectedPermissions = this.affiliatesTeam.permissions || []
    },
    async save(ev) {
      try {
        await this.$store.dispatch('affiliatesTeams/saveTeamPermissions', {
          id: this.affiliatesTeam._id,
          permissions: this.selectedPermissions
        })
        await this.$toast.success('Save completed successfully')
        this.cancel()
      } catch (e) {
        await this.$toast.error(e.response.data.message)
      }
    },
    cancel() {
      this.selectedPermissions = []
      this.dialog = false
    }
  }
}
</script>

<style lang="scss">
.perm-section {
  border: 1px solid #ebebeb;
  margin-top: 10px;
  border-radius: 7px;
  margin-bottom: 10px;
  background-color: #f5f5f5;

  .perm-section-title {
    padding: 7px;
    border-bottom: 1px solid #ebebeb;
  }

  .perm-section-content {
    padding: 7px;
    background-color: #fff;
  }
}
</style>
